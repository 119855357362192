import { render, staticRenderFns } from "./OU.vue?vue&type=template&id=6dc54f27&scoped=true"
import script from "./OU.vue?vue&type=script&lang=js"
export * from "./OU.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6dc54f27",
  null
  
)

export default component.exports