<template>
  <span>
    <span v-if="!mgr.is_sub && !mgr.is_svc" :class="account_is_locked ? 'text-danger' : ''" :title="account_is_locked ? $t('views.app.user_locked_text') : null">
      {{ name_bundle }}
      <b-link v-if="mgr.email" :href="`mailto:${mgr.email}`" class="mr-1">
        <netvs-icon icon="mail" />
      </b-link>
    </span>
    <span v-else-if="mgr.is_svc">
      <RouterLink :to="'/cntl/svc-accounts/' + mgr.login_name" class="mr-1">{{ mgr.login_name }}</RouterLink>
    </span>
    <span v-else>
      {{ mgr.login_name }}
    </span>
    <b-badge class="mr-1" variant="danger"
           v-if="account_is_locked"
             :title="$t('views.app.user_locked_text')">
      <netvs-icon icon="user_locked" />
    </b-badge>
    <b-badge v-if="mgr.is_svc" class="mr-1" variant="warning"
             :title="$tc('components.svc_card.svc_account', 1)">
      <netvs-icon icon="svc_account"></netvs-icon>
      {{ $t('components.svc_card.svc') }}
      <span v-if="mgr.svc_id"
            :title="$t('components.svc_card.svc_id')">({{ mgr.svc_id }})</span>
    </b-badge>
    <b-badge v-if="mgr.is_sub" class="mr-1" variant="danger"
             :title="$tc('components.svc_card.sub_account', 1)">{{ $t('components.svc_card.sub') }}
    </b-badge>
    <b-badge v-if="!mgr.allow_data_manipulation" class="mr-1" variant="info"
             :title="$t('components.svc_card.read_only_description')">
      <netvs-icon icon="eye"></netvs-icon>
      {{ $t('components.svc_card.read_only') }}
    </b-badge>
    <b-badge class="mr-1" variant="info" v-if="$store.state.user && mgr.login_name === ($store.state.impersonate_user || $store.state.user?.login_name)">{{ $t('system.you') }}</b-badge>
  </span>
</template>

<script>
export default {
  name: 'MgrLine',
  props: {
    mgr: {
      type: Object,
      required: true
    },
  },
  computed: {
    name_bundle() {
      if (this.mgr.first_name === this.mgr.login_name || this.mgr.last_name === this.mgr.login_name) {
        return this.mgr.login_name
      }
      return this.mgr.first_name + ' ' + this.mgr.last_name + ' (' + this.mgr.login_name + ')'
    },
    account_is_locked() {
      return this.mgr.is_adm_locked || this.mgr.is_deletable || (!this.mgr.is_svc && this.mgr.kit_status <= 0)
    }
  }
}
</script>
