<template>
  <div class="paginator">
    <FilterInput v-if="!hideFilter" v-model="filterValue"></FilterInput>
    <Paginator :current-page="current_page" :per-page="items_per_page" @current-page="current_page = $event"
               @per-page="setPerPage($event)" :total-rows="total_rows" :block="block"
               :per-page-options="itemsPerPageOptions"
               v-if="items && total_rows > 0 && !hideTopPagination"/>

    <div class="pagination_container">
      <slot name="content_skeleton_item" v-if="!items || items.length === 0"></slot>
      <b-table :filter="filterValue" :items="items" v-bind="$attrs" :current-page="current_page"
               @filtered="onFiltered" :busy="isBusy" :sort-by="sortBy"
               :per-page="items_per_page">
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
          <slot :name="slot" v-bind="scope"/>
        </template>
      </b-table>
    </div>
    <div v-if="show_default_no_items_warning && (!items || total_rows === 0)"
         class="font-italic text-center my-3">
      <span class="font-italic">{{ $t(noItemsTranslationKey) }}</span><br/>
    </div>

    <Paginator :current-page="current_page" :per-page="items_per_page" @current-page="current_page = $event"
               @per-page="setPerPage($event)" :total-rows="total_rows" :block="block"
               :per-page-options="itemsPerPageOptions"
               v-if="items && total_rows > 0"/>
  </div>
</template>

<script>
import FilterInput from '@/components/FilterInput'
import Paginator from '@/components/Paginator'

export default {
  name: 'PaginatorTable',
  components: { FilterInput, Paginator },
  data() {
    return {
      current_page: 1,
      items_per_page: 25,
      filterValue: '',
      total_rows: 0
    }
  },
  mounted() {
    if (this.items) {
      this.total_rows = this.items.length
    }
  },
  props: {
    items: {
      required: true,
      default() {
        return null
      }
    },
    initialItemsPerPage: {
      default() {
        return 25
      }
    },
    itemsPerPageOptions: {
      default() {
        return [
          5,
          25,
          50,
          100,
          500,
          { value: 0, translation_key: 'components.paginator.all' }
        ]
      }
    },
    block: {
      type: Boolean,
      default() {
        return false
      }
    },
    noItemsTranslationKey: {
      default() {
        return 'components.paginator_list.no_entries'
      }
    },
    hideTopPagination: {
      default() {
        return false
      }
    },
    hideFilter: {
      default() {
        return false
      }
    },
    filterFunction: {
      type: Function,
      default() {
        return function (item, term) {
          if (typeof term === 'string') {
            term = term.toLowerCase()
          }
          for (const v of Object.values(item)) {
            if (typeof v === 'string') {
              if (v.toLowerCase().includes(term)) {
                return true
              }
            }
          }
          return false
        }
      }
    },
    sortFunction: {
      type: Function,
      default() {
        return null
      }
    },
    isBusy: {
      type: Boolean,
      default() {
        return false
      }
    },
    sortBy: {
      type: String,
      default() {
        return null
      }
    },
    show_default_no_items_warning: {
      type: Boolean,
      default() {
        return true
      }
    }
  },
  watch: {
    items(newVal, oldVal) {
      if (newVal) {
        this.current_page = Math.min(this.current_page, Math.floor(newVal.length / this.items_per_page) + 1) || 1
        this.total_rows = newVal.length
      }
    }
  },
  methods: {
    setPerPage(itemsPerPage) {
      this.items_per_page = itemsPerPage
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.total_rows = filteredItems.length
      this.currentPage = 1
    }
  }
}
</script>

<style scoped>

</style>
