import TransactionService from './transaction.service'

export default {
  getTypes(config) {
    const ta = [
      {
        name: 'org.unit_type.list',
      }
    ]
    return TransactionService.execute(config, ta)
  }
}
