import TransactionService from './transaction.service'

export default {
  resolveParents(config, ou) {
    const ta = [
      {idx: 'parent_ou', name: 'org.unit.list', old: {short_name: ou}},
      {
        name: 'org.unit.list',
        idx: 'parent_ous',
        inner_join_ref: {parent_ou: 'api_func_org_unit_hierarchy_gfk_list_is_subset'}
      }
    ]
    return TransactionService.execute(config, ta)
  },
  getOU(config, ou) {
    const ta = [
      {idx: 'current_ou', name: 'org.unit.list', old: {short_name: ou}},
      {idx: 'child_ous', name: 'org.unit.list', inner_join_ref: {current_ou: 'api_func_org_unit_hierarchy_gfk_list_is_superset'}},
      {
        idx: 'parent_ous',
        name: 'org.unit.list',
        old: {sorting_params_list: ['tree_level DESC']},
        inner_join_ref: {current_ou: 'api_func_org_unit_hierarchy_gfk_list_is_subset'}
      },
      {name: 'cntl.mgr2ou.list', inner_join_ref: {current_ou: 'default'}},
      {name: 'cntl.mgr.list', inner_join_ref: {mgr2ou_list: 'default'}},
      {name: 'dns.fqdn2ou.list', inner_join_ref: {current_ou: 'default'}},
      {name: 'nd.bcd2ou.list', inner_join_ref: {current_ou: 'default'}},
      {name: 'nd.bcd.list', inner_join_ref: {bcd2ou_list: 'default'}},
      {
        name: 'cntl.group.list',
        inner_join_ref: {child_ous: 'default'},
        old: {sorting_params_list: ['name']}
      },
      {idx: 'recursive_fqdn2ou_list', name: 'dns.fqdn2ou.list', inner_join_ref: {parent_ous: 'default'}, old: {is_recursive: true}},
      {idx: 'recursive_bcd2ou_list', name: 'nd.bcd2ou.list', inner_join_ref: {parent_ous: 'default'}, old: {is_recursive: true}},
      {idx: 'recursive_mgr2ou_list', name: 'cntl.mgr2ou.list', inner_join_ref: {parent_ous: 'default'}},
      {idx: 'recursive_mgr_list', name: 'cntl.mgr.list', inner_join_ref: {recursive_mgr2ou_list: 'default'}},
      {idx: 'recursive_bcd_list', name: 'nd.bcd.list', inner_join_ref: {recursive_bcd2ou_list: 'default'}},
      {name: 'org.unit_type.list', inner_join_ref: {current_ou: 'default'}}
    ]
    return TransactionService.execute(config, ta)
  },
  listOwn(config) {
    const ta = [
      {idx: 'own_mgr2ou_list', name: 'cntl.mgr2ou.list', old: {is_own: true}},
      {
        name: 'org.unit.list',
        inner_join_ref: {own_mgr2ou_list: 'default'},
        old: {sorting_params_list: ['sub_position']}
      },
      {
        idx: 'unit_list_superset',
        name: 'org.unit.list',
        inner_join_ref: {unit_list: 'api_func_org_unit_hierarchy_gfk_list_is_superset'}
      },
      {name: 'cntl.mgr2ou.list', inner_join_ref: {unit_list_superset: 'default'}},
      {name: 'cntl.mgr.list', inner_join_ref: {mgr2ou_list: 'default'}},
      {name: 'dns.fqdn2ou.list', inner_join_ref: {unit_list_superset: 'default'}},
      {name: 'nd.bcd2ou.list', inner_join_ref: {unit_list_superset: 'default'}},
      {name: 'nd.bcd.list', inner_join_ref: {bcd2ou_list: 'default'}},
      {name: 'org.unit_type.list', inner_join_ref: {unit_list: 'default'}}
    ]
    return TransactionService.execute(config, ta)
  },
  listAll(config) {
    const ta = [
      {
        name: 'org.unit.list',
        old: {sorting_params_list: ['sub_position']}
      },
      {name: 'cntl.mgr2ou.list', inner_join_ref: {unit_list: 'default'}},
      {name: 'cntl.mgr.list', inner_join_ref: {mgr2ou_list: 'default'}},
      {name: 'dns.fqdn2ou.list', inner_join_ref: {unit_list: 'default'}},
      {name: 'nd.bcd2ou.list', inner_join_ref: {unit_list: 'default'}},
      {name: 'nd.bcd.list', inner_join_ref: {bcd2ou_list: 'default'}},
      {name: 'org.unit_type.list', inner_join_ref: {unit_list: 'default'}}
    ]
    return TransactionService.execute(config, ta)
  }
}
