// -- AUTOGENERATED --

// eslint-disable-next-line no-unused-vars
import APIUtils from '@/util/apiutil'

import Axios from 'axios'
import NETVSConfig from '@/../netvs.config'

/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable object-curly-spacing */
/* eslint-disable array-bracket-spacing */
/* eslint-disable object-shorthand */
export default {

  createParamsList () {
    return {'old': [ ], 'new': [ 'name', 'type', 'short_name', 'sub_position', 'is_mgr_assignable', 'parent_short_name']}
  },
  create (config, {name_new, type_new, short_name_new, sub_position_new, is_mgr_assignable_new = true, parent_short_name_new}) {
    const params = { 'new': { 'name': name_new, 'type': type_new, 'short_name': short_name_new, 'sub_position': sub_position_new, 'is_mgr_assignable': is_mgr_assignable_new, 'parent_short_name': parent_short_name_new}, 'old': { }}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/org/unit/create`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
  deleteParamsList () {
    return {'old': [ 'short_name'], 'new': [ 'merge_short_name']}
  },
  delete (config, {short_name_old, merge_short_name_new}) {
    const params = { 'new': { 'merge_short_name': merge_short_name_new}, 'old': { 'short_name': short_name_old}}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/org/unit/delete`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
  list (config, { gpk, type, gpk_list, short_name, tree_level, fetch_limit, name_regexp, fetch_offset, short_name_list, is_mgr_assignable, short_name_regexp, filter_params_dict, sorting_params_list }) {
    const params = APIUtils.denullify_dict({ 'gpk': gpk, 'type': type, 'gpk_list': (gpk_list === null) ? null : JSON.stringify(gpk_list), 'short_name': short_name, 'tree_level': tree_level, 'fetch_limit': fetch_limit, 'name_regexp': name_regexp, 'fetch_offset': fetch_offset, 'short_name_list': (short_name_list === null) ? null : JSON.stringify(short_name_list), 'is_mgr_assignable': is_mgr_assignable, 'short_name_regexp': short_name_regexp, 'filter_params_dict': filter_params_dict, 'sorting_params_list': (sorting_params_list === null) ? null : JSON.stringify(sorting_params_list)})
    const cnf = {}
    Object.assign(cnf, (config || {}).netdb_axios_config)
    cnf.params = params
    return Axios.get(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/org/unit/list`, cnf)
  // eslint-disable-next-line comma-dangle
  },
  updateParamsList () {
    return {'old': [ 'short_name'], 'new': [ 'name', 'type', 'short_name', 'sub_position', 'is_mgr_assignable', 'parent_short_name']}
  },
  update (config, {name_new, type_new, short_name_old, short_name_new, sub_position_new, is_mgr_assignable_new, parent_short_name_new}) {
    const params = { 'new': { 'name': name_new, 'type': type_new, 'short_name': short_name_new, 'sub_position': sub_position_new, 'is_mgr_assignable': is_mgr_assignable_new, 'parent_short_name': parent_short_name_new}, 'old': { 'short_name': short_name_old}}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/org/unit/update`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
}
