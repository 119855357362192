// -- AUTOGENERATED --

// eslint-disable-next-line no-unused-vars
import APIUtils from '@/util/apiutil'

import Axios from 'axios'
import NETVSConfig from '@/../netvs.config'

/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable object-curly-spacing */
/* eslint-disable array-bracket-spacing */
/* eslint-disable object-shorthand */
export default {

  createParamsList () {
    return {'old': [ ], 'new': [ 'bcd_name', 'is_recursive', 'ou_short_name']}
  },
  create (config, {bcd_name_new, is_recursive_new = false, ou_short_name_new}) {
    const params = { 'new': { 'bcd_name': bcd_name_new, 'is_recursive': is_recursive_new, 'ou_short_name': ou_short_name_new}, 'old': { }}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/nd/bcd2ou/create`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
  deleteParamsList () {
    return {'old': [ 'bcd_name', 'ou_short_name'], 'new': [ ]}
  },
  delete (config, {bcd_name_old, ou_short_name_old}) {
    const params = { 'new': { }, 'old': { 'bcd_name': bcd_name_old, 'ou_short_name': ou_short_name_old}}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/nd/bcd2ou/delete`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
  list (config, { gpk, is_own, bcd_name, gpk_list, fetch_limit, fetch_offset, is_recursive, ou_short_name, filter_params_dict, sorting_params_list }) {
    const params = APIUtils.denullify_dict({ 'gpk': gpk, 'is_own': is_own, 'bcd_name': bcd_name, 'gpk_list': (gpk_list === null) ? null : JSON.stringify(gpk_list), 'fetch_limit': fetch_limit, 'fetch_offset': fetch_offset, 'is_recursive': is_recursive, 'ou_short_name': ou_short_name, 'filter_params_dict': filter_params_dict, 'sorting_params_list': (sorting_params_list === null) ? null : JSON.stringify(sorting_params_list)})
    const cnf = {}
    Object.assign(cnf, (config || {}).netdb_axios_config)
    cnf.params = params
    return Axios.get(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/nd/bcd2ou/list`, cnf)
  // eslint-disable-next-line comma-dangle
  },
  updateParamsList () {
    return {'old': [ 'bcd_name', 'ou_short_name'], 'new': [ 'is_recursive', 'ou_short_name']}
  },
  update (config, {bcd_name_old, is_recursive_new, ou_short_name_old, ou_short_name_new}) {
    const params = { 'new': { 'is_recursive': is_recursive_new, 'ou_short_name': ou_short_name_new}, 'old': { 'bcd_name': bcd_name_old, 'ou_short_name': ou_short_name_old}}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/nd/bcd2ou/update`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
}
