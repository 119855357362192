<template>
  <div>
    <p v-if="filter_match">
      <b-button variant="link" class="mr-2 px-2 py-1" v-b-toggle="ou.short_name + '-children'"
                :style="{color: (ou.short_name + ou.name).toHSL()}"
                v-if="ous_by_parent[ou.short_name] && ous_by_parent[ou.short_name].length > 0">
        <netvs-icon class="collapse-icon" icon="collapse"></netvs-icon>
      </b-button>
      <b-link class="mr-3" :to="'/org/ou/'+ou.short_name">{{ ou.name }} ({{ ou.short_name }})</b-link>
      <b-button v-if="can_edit_ou" variant="link" :id="'edit-ou-' + ou.short_name"
                @click="edit_ou(ou)" class="m-0 p-0">
        <netvs-icon icon="edit"></netvs-icon>
      </b-button>
      <b-button v-if="can_create_ou" variant="link" :id="'create-ou-' + ou.short_name"
                @click="create_ou(ou)" class="m-0 p-0 ml-1">
        <netvs-icon icon="create"></netvs-icon>
      </b-button>
    </p>
    <div class="pl-4 ml-2" v-if="ous_by_parent[ou.short_name]"
         :style="{'border-left': `6px solid ${(ou.short_name + ou.name).toHSL()}`, borderRadius: '14px'}">
      <b-collapse :id="ou.short_name + '-children'" visible>
        <OUTreeEntry v-for="child_ou in ous_by_parent[ou.short_name]" :key="child_ou.short_name" :ou="child_ou"
                     :ous_by_parent="ous_by_parent" :edit_ou="edit_ou" :create_ou="create_ou" :filter="filter"/>
      </b-collapse>
    </div>
  </div>
</template>

<script>

import apiutil from '@/util/apiutil'

/**
 * @displayName OUTreeEntry
 * @description Component for recursive rendering of OU tree
 */
export default {
  name: 'OUTreeEntry',
  props: {
    ou: {
      type: Object,
      required: true
    },
    ous_by_parent: {
      type: Object,
      required: true
    },
    edit_ou: {
      type: Function,
      required: false,
      default: (ou) => {
        window.console.log('No DBEditor handler was passed to edit', ou)
      }
    },
    create_ou: {
      type: Function,
      required: false,
      default: (ou) => {
        window.console.log('No DBEditor handler was passed to create sub-ou for', ou)
      }
    },
    can_create_ou: {
      type: Boolean,
      required: false,
      default: false
    },
    filter: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      db_editor_presets: this.ou
    }
  },
  computed: {
    filter_match() {
      return this.filter === '' ||
        this.ou.name.toLowerCase().includes(this.filter.toLowerCase()) ||
        this.ou.short_name.toLowerCase().includes(this.filter.toLowerCase())
    },
    can_edit_ou() {
      return apiutil.checkPermission(this.$store.state, 'org.rw:unit')
    }
  },
}
</script>

<style scoped>
.not-collapsed > .collapse-icon {
  transform: rotate(-180deg);
}
</style>
