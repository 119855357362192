<template>
  <DBEditor v-if="org_types_by_name" object_function="create" object_fq_name="org.unit"
                   :modal_id="modal_id"
                   :presets="presets"
                   :input_reducer="reducer"
                   :non_optionals_order="['name', 'short_name', 'parent_short_name', 'type']"/>
</template>

<script>
import DBEditor from '@/components/db-editor/APIObjectDBEditor.vue'
import TypeService from '@/api-services/unit_types.service'
import apiutil from '@/util/apiutil'

export default {
  name: 'CreateOU',
  components: { DBEditor },
  async created() {
    const typeQuery = await TypeService.getTypes(this.$store.state.netdb_axios_config)
    this.org_types_by_name = apiutil.dict_by_value_of_array(typeQuery.data.unit_type_list, 'short_name_list')
    const types = typeQuery.data.unit_type_list
    const selections = {}
    for (const t of types) {
      selections[t.short_name] = { display_name: t.name }
    }
    this.full_create_org_reducer = {
      type: selections
    }
  },
  computed: {
    reducer() {
      return this.full_create_org_reducer
    },
  },
  data() {
    return {
      template: null,
      full_create_org_reducer: {},
      org_types_by_name: null,
    }
  },
  props: {
    modal_id: {
      type: String,
      required: true
    },
    presets: {
      type: Object,
      required: false,
      default() {
        return null
      }
    },
  }
}
</script>

<style scoped>

</style>
